import React, { useEffect } from 'react'
import BasePage from '../../components/basePage'
import PlayerItem from '../../components/playerItem'
import Modal from '../../components/modal'
import { Flex, Heading, Button, Image, useToast } from '@chakra-ui/react'
import Avatar from '../../images/avatars/1.png'
import { useState } from 'react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
	getTeam,
	getTeamMembers,
	updateTeam,
	deleteTeam,
	joinTeam,
	leaveTeam,
	myCurrentTeam,
	getGroupTeams
} from '../../services/team'
import { useAuth } from '../../hooks/useAuth'
import { navigate } from 'gatsby'
import {
	getGroup,
	getGroupMembers,
	joinGroup,
	removeUserFromGroup
} from '../../services/group'
import Spinner from '../../components/spinner'
import { yupResolver } from '@hookform/resolvers/yup'
import { object, string } from 'yup'
import { useForm } from 'react-hook-form'
import { RWebShare } from 'react-web-share'
import { errorToastOptions, getImageById } from '../../helpers'
import Input from '../../components/input'
import eventTracking from '../../services/eventTracking'
import ShareBlack from '../../icons/share-black.svg'
import RFIDLinkButton from '../../components/RFIDLinkButton'

const TeamPage = ({ id, teamId }) => {
	const toast = useToast()
	const queryClient = useQueryClient()
	const { user } = useAuth()
	const [team, setTeam] = useState(null)
	const [editing, setEditing] = useState(false)
	const [deleteModal, toggleDeleteModal] = useState(false)
	const [openModal, setOpenModal] = useState(false)
	const [leaveModal, toggleLeaveModal] = useState(false)
	const [memberModal, toggleMemberModal] = useState(false)

	const resolver = yupResolver(
		object()
			.shape({
				name: string().notBadWords().trim().required().min(2)
			})
			.required()
	)
	const {
		register,
		handleSubmit,
		getValues,
		setValue,
		formState: { errors }
	} = useForm({
		resolver,
		mode: 'onSubmit',
		reValidateMode: 'onChange',
		shouldUseNativeValidation: false
	})

	const { data, status } = useQuery(['team', teamId], getTeam)
	const currentTeam = useQuery(['currentTeam', id], myCurrentTeam)
	const groupQuery = useQuery(['group', id], getGroup)
	const groupMembersQuery = useQuery(['groupMembers', id], getGroupMembers)
	const membersQuery = useQuery(['teamMembers', teamId], getTeamMembers)
	const teamsQuery = useQuery(['groupTeams', id], getGroupTeams)

	const nameMutation = useMutation(updateTeam, {
		onSuccess: async (data) => {
			setTeam({ ...team, name: data.name })
			await queryClient.invalidateQueries(['team', teamId], {
				exact: true
			})
			setEditing(false)
		},
		onError: (error) => {
			toast({
				description: error.message || error,
				...errorToastOptions
			})
		}
	})

	const joinTeamMutation = useMutation(joinGroup, {
		onSuccess: async (data) => {
			setOpenModal(true)
			await Promise.all([
				queryClient.invalidateQueries(['team', teamId]),
				queryClient.invalidateQueries(['teamMembers', teamId], {
					exact: true
				})
			])

			navigate(`/rfid/?team=${teamId}`) // Redirect without param after joining
		},
		onError: (error) => {
			toast({
				description: error.message || error,
				...errorToastOptions
			})
		}
	})

	const leaveGroupMutation = useMutation(removeUserFromGroup, {
		onSuccess: async (data) => {
			await queryClient.invalidateQueries(['group'], { exact: true })
			toggleLeaveModal(null)
			await navigate('/groups/')
		},
		onError: (error) => {
			toast({
				description: error.message || error,
				...errorToastOptions
			})
		}
	})

	const removeUserMutation = useMutation(removeUserFromGroup, {
		onSuccess: async (data) => {
			await queryClient.invalidateQueries(['teamMembers', teamId], {
				exact: true
			})
			await queryClient.invalidateQueries(['group'], {
				exact: true
			})
			toggleMemberModal(null)
			await membersQuery.refetch()
			await groupQuery.refetch()
			await groupMembersQuery.refetch()
		},
		onError: (error) => {
			toast({
				description: error.message || error,
				...errorToastOptions
			})
		}
	})

	const deleteTeamMutation = useMutation(deleteTeam, {
		onSuccess: async (data) => {
			queryClient.invalidateQueries(['team', teamId])
			toggleDeleteModal(null)
			await navigate(`/groups/${id}/`)
		},
		onError: (error) => {
			toast({
				description: error.message || error,
				...errorToastOptions
			})
		}
	})

	const shareUrl =
		typeof window !== 'undefined'
			? `${window.location.origin}/groups/${id}/team/${teamId}`
			: ''
	const shareData = {
		title: `Fairgame Team: ${team?.name || ''}`,
		text: `I’m inviting you to join the team “${
			team?.name || ''
		}” so we can play together at Fairgame`,
		url: shareUrl
	}

	// const canEditTeam = team?.state === 0
	const canEditTeam = true
	const canEditMyTeam = true
	// const canEditMyTeam =
	// 	!!currentTeam.data &&
	// 	(!currentTeam.data?.state || currentTeam.data?.state === 0)

	const onRemoveUser = (item) => {
		toggleMemberModal(item)
	}

	const onLeaveGroup = () => {
		toggleLeaveModal(groupQuery.data)
	}

	const handleShare = async () => {
		if (typeof navigator !== 'undefined') {
			await navigator.share({
				text: `I’m inviting you to join the team “${
					team?.name || ''
				}” so we can play together at Fairgame: ${shareUrl}`
			})
		}

		eventTracking('share_team', {
			group_id: id,
			team_id: teamId,
			user_id: user?.uid
		})
	}

	const handleEditName = () => {
		const data = getValues()
		if (!data?.name) return
		nameMutation.mutate({ id: teamId, name: data.name })
	}

	const handelJoinTeam = () => {
		joinTeamMutation.mutate({ id, teamId })
	}

	const handleLeaveGroup = () => {
		leaveGroupMutation.mutate({ id, memberId: memberModal.memberId })
	}

	const handleRemoveUser = () => {
		removeUserMutation.mutate({
			id,
			teamId,
			memberId: memberModal.memberId
		})
	}

	useEffect(() => {
		if (
			id &&
			teamId &&
			user?.uid &&
			typeof window !== 'undefined' &&
			window.location.search.indexOf('autojoin') > -1
		) {
			handelJoinTeam()
		}
	}, [id, teamId, user])

	const handleDeleteTeam = () => {
		deleteTeamMutation.mutate({ teamId })
	}

	useEffect(() => {
		if (status === 'error' || (status === 'success' && !data.name)) {
			navigate(`/groups/${id}/`)
		}
		if (
			groupQuery.status === 'error' ||
			(groupQuery.status === 'success' && !groupQuery.data?.name)
		) {
			navigate('/groups/')
		}
		if (status === 'success' && !!data?.name) {
			setTeam(data)
			setValue('name', data.name)
		}
	}, [data, status, groupQuery])

	if (
		!team ||
		status === 'loading' ||
		status === 'error' ||
		groupQuery.status === 'loading' ||
		membersQuery.status === 'loading'
	) {
		return (
			<BasePage
				theme="dark"
				backBtn={`/groups/${id}/`}
				showMenuBtn={true}
			>
				{status === 'loading' && <Spinner />}
			</BasePage>
		)
	}
	const rfid =
		groupMembersQuery?.data?.find((member) => member.id === user.uid)
			?.rfid || false

	return (
		<BasePage
			backBtn={`/groups/${id}/team`}
			theme="dark"
			showMenuBtn={true}
			rightBtn={
				team?.isMember ? (
					<RFIDLinkButton teamId={team.id} rfid={rfid} />
				) : null
			}
		>
			<Flex
				direction="column"
				justifyContent="center"
				alignItems="center"
				mb="70px"
				w="100%"
			>
				<Flex h="120px">
					<Image
						width="120px"
						height="120px"
						src={
							!!team?.avatar ? getImageById(team.avatar) : Avatar
						}
					/>
				</Flex>
				{editing ? (
					<Flex direction="column">
						<form onSubmit={handleSubmit(handleEditName)}>
							<Input
								type="text"
								name="name"
								mt="6px"
								px="20px"
								w="233px"
								minW="233px"
								maxW="233px"
								variant="filled"
								fontSize="40px"
								fontWeight="900"
								textAlign="center"
								textTransform="uppercase"
								register={register}
								errors={errors}
							/>
						</form>
					</Flex>
				) : (
					<Heading
						mt="20px"
						as="h1"
						mb={canEditTeam ? '0' : '30px'}
						color="white"
						w="100%"
						whiteSpace="nowrap"
						overflow="hidden"
						textOverflow="ellipsis"
					>
						{team?.name || ''}
					</Heading>
				)}
				{!!team?.isMember && canEditTeam && editing && canEditMyTeam && (
					<Button
						type="submit"
						mt="10px"
						variant="text"
						fontWeight="500"
						fontSize="18px"
						lineHeight="20px"
						onClick={handleSubmit(handleEditName)}
						disabled={nameMutation.isLoading}
						isLoading={nameMutation.isLoading}
					>
						Save
					</Button>
				)}
				{!!team?.isMember && canEditTeam && !editing && canEditMyTeam && (
					<Button
						mt="10px"
						variant="text"
						fontWeight="500"
						fontSize="18px"
						lineHeight="20px"
						onClick={() => setEditing(true)}
					>
						Edit name
					</Button>
				)}
				{!team?.isMember && canEditTeam && canEditMyTeam && (
					<Button
						w="100%"
						my="30px"
						variant="primary"
						onClick={handelJoinTeam}
						isLoading={joinTeamMutation.isLoading}
						disabled={joinTeamMutation.isLoading}
					>
						Join Team
					</Button>
				)}
				{/* {!!team?.isMember && (
					<Button
						w="100%"
						variant="secondaryBorder"
						mt="30px"
						mb="-20px"
						onClick={() => {
							navigate(`/rfid/?team=${teamId}`)
						}}
					>
						<WifiWhite style={{ marginRight: '8px' }} />
						Link RFID
					</Button>
				)} */}
				{!!team?.isMember && canEditTeam && canEditMyTeam && (
					<>
						{typeof navigator !== 'undefined' && navigator.share ? (
							<Button
								mt="30px"
								mb="40px"
								w="100%"
								variant="primary"
								onClick={handleShare}
							>
								<ShareBlack
									style={{
										marginRight: 8
									}}
								/>
								Share Team
							</Button>
						) : (
							<RWebShare data={shareData}>
								<Button
									mt="30px"
									mb="40px"
									w="100%"
									variant="primary"
								>
									<ShareBlack
										style={{
											marginRight: 8
										}}
									/>
									Share Team
								</Button>
							</RWebShare>
						)}
					</>
				)}
				<Flex
					direction="column"
					justifyContent="center"
					alignItems="center"
					w="100%"
					mt={
						team?.state !== 0 || currentTeam.data?.state !== 0
							? '30px'
							: '0'
					}
				>
					{(membersQuery.data || []).map((item) => {
						const matchingGroupMember =
							groupMembersQuery?.data?.find(
								(groupMember) =>
									groupMember.nickname === item.nickname
							)

						return (
							<PlayerItem
								key={item.id}
								player={item}
								isMyself={item.memberId === user?.uid}
								isMember={!!team?.isMember && canEditTeam}
								isLastMember={
									(team?.state === 0 &&
										groupQuery.data?.amountOfMembers ===
											1) ||
									!!matchingGroupMember?.rfid
								}
								onLeaveGroup={onLeaveGroup}
								onRemoveUser={onRemoveUser}
							/>
						)
					})}
				</Flex>
				{!!team &&
					(teamsQuery.data || []).length > 1 &&
					(membersQuery.data || []).length === 0 && (
						<Button
							variant="text"
							mt="8px"
							onClick={() => toggleDeleteModal(true)}
						>
							Delete team
						</Button>
					)}
			</Flex>
			<Modal
				opened={openModal}
				onSubmit={handelJoinTeam}
				// onClose={() => setOpenModal(null)}
				title="Congrats!"
				description="You’ve successfully joined"
				group={team}
				primaryBtn={
					<Button
						variant="primary"
						onClick={() => setOpenModal(false)}
					>
						Close
					</Button>
				}
			/>
			<Modal
				opened={!!leaveModal}
				// onClose={() => toggleLeaveModal(null)}
				title={
					<>
						Leave <br />
						group?
					</>
				}
				isPink
				description="Are you sure you want to permanently leave this group?"
				member={leaveModal}
				primaryBtn={
					<Button
						variant="primary"
						isLoading={leaveGroupMutation.isLoading}
						disabled={leaveGroupMutation.isLoading}
						onClick={handleLeaveGroup}
					>
						Leave
					</Button>
				}
				secondaryBtn={
					<Button
						variant="cancel"
						onClick={() => toggleLeaveModal(null)}
					>
						Cancel
					</Button>
				}
			/>
			<Modal
				opened={!!memberModal}
				// onClose={() => toggleMemberModal(null)}
				title={
					<>
						Are you <br />
						sure?
					</>
				}
				description="Are you sure you want to delete this player from the group? They will no longer be able to play."
				member={memberModal}
				primaryBtn={
					<Button
						variant="primary"
						isLoading={removeUserMutation.isLoading}
						disabled={removeUserMutation.isLoading}
						onClick={handleRemoveUser}
					>
						Delete
					</Button>
				}
				secondaryBtn={
					<Button
						variant="cancel"
						onClick={() => toggleMemberModal(null)}
					>
						Cancel
					</Button>
				}
			/>
			<Modal
				opened={!!deleteModal}
				// onClose={() => toggleDeleteModal(null)}
				title={
					<>
						Delete <br />
						team?
					</>
				}
				description="Are you sure you want to delete this team? This change cannot be undone. "
				member={{
					...(team || {}),
					teamAvatar: !!team?.avatar
						? getImageById(team?.avatar)
						: Avatar
				}}
				primaryBtn={
					<Button
						variant="primary"
						isLoading={deleteTeamMutation.isLoading}
						disabled={deleteTeamMutation.isLoading}
						onClick={handleDeleteTeam}
					>
						Delete
					</Button>
				}
				secondaryBtn={
					<Button
						variant="cancel"
						onClick={() => toggleDeleteModal(null)}
					>
						Cancel
					</Button>
				}
			/>
		</BasePage>
	)
}
export default TeamPage
