// import * as React from 'react'
// import { Router, useLocation } from '@reach/router'
// import { navigate } from 'gatsby'

// const Team = (props) => {
// 	React.useEffect(() => {
// 		if (props.uri) {
// 			// Redirect to group page
// 			navigate(props.uri.split('/team')[0])
// 		}
// 	}, [props])
// 	return <div></div>
// }

// export default Team

import * as React from 'react'
import Layout from '../../../../../layout'
import TeamPage from '../../../../../pagesComponents/groups/team'

import openGraphShareCard from '../../../../../images/opengraph-sharecard.png'

import Helmet from '../../../../../components/Helmet'

const Team = ({ id, teamId }) => {
	return (
		<Layout>
			<Helmet
				title="Join my Fairgame Team"
				ogTitle="I’m inviting you to join my Fairgame team"
				description="The funfair. exactly like you don't remember. Brand spankin' new venue in Canary Wharf! Welcome."
				image={openGraphShareCard}
			/>
			<TeamPage id={id} teamId={teamId} />
		</Layout>
	)
}

export default Team
